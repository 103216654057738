import Keycloak, { KeycloakProfile } from 'keycloak-js';

export default interface User {
  profile?: KeycloakProfile;
  isAuthenticated(): boolean;
}

export class KeycloakUser implements User {
  keycloak: Keycloak;
  profile?: KeycloakProfile;

  constructor(keycloak: Keycloak, profile?: KeycloakProfile) {
    this.keycloak = keycloak;
    this.profile = profile;
    // this.keycloak.onAuthSuccess = () => {
    //   this.keycloak.loadUserProfile().then((profile) => {
    //     this.profile = profile;
    //     console.log("Loaded profile: ", profile);
    //   }).catch(() => {
    //     alert('Failed to load user profile');
    //   })
    // }
  }

  isAuthenticated(): boolean {
    if (this.keycloak.authenticated) {
      return this.keycloak.authenticated;
    } else {
      return false;
    }
  }
}
