import { Fragment, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AccountIcon from '@mui/icons-material/AccountCircle';
import { useKeycloak } from '../../hooks/keycloak';
import { login, logout } from '../../hooks/keycloak';

export default function ProfileButton() {
  const [keycloak] = useKeycloak();
  const [profileMenuAnchor, setProfileMenuAnchor] = useState<null | HTMLElement>(null);
  const profileMenuOpen = Boolean(profileMenuAnchor);

  function showProfileMenu(event: React.MouseEvent<HTMLElement>) {
    setProfileMenuAnchor(event.currentTarget);
  }

  function closeProfileMenu() {
    setProfileMenuAnchor(null);
  }

  return (
    <Fragment>
      <label>
        {keycloak.keycloak.authenticated && `${keycloak.user.profile?.firstName} ${keycloak.user.profile?.lastName}`}
        <IconButton color='inherit' onClick={showProfileMenu} size='large'>
          <AccountIcon fontSize='large' />
        </IconButton>
      </label>
      <Menu
        anchorEl={profileMenuAnchor}
        id='account-menu'
        open={profileMenuOpen}
        onClose={closeProfileMenu}
        onClick={closeProfileMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!keycloak.keycloak.authenticated && <MenuItem onClick={login}>Login</MenuItem>}
        {keycloak.keycloak.authenticated && <MenuItem onClick={logout}>Logout</MenuItem>}
      </Menu>
    </Fragment>
  );
}
