import { Coordinate, toStringXY } from 'ol/coordinate';

interface Store {
  get(key: string): string | undefined;
  set(key: string, obj: any): void;
}

class StorageBackend implements Store {
  store: Storage;

  constructor(store: Storage) {
    this.store = store;
  }

  get(key: string): string | undefined {
    const value = this.store.getItem(key);
    if (value !== null) {
      return value;
    }
    return undefined;
  }

  getOrDefault(key: string, def: string): string {
    const value = this.get(key);
    if (!value) {
      return def;
    }
    return value;
  }

  set(key: string, value: string): void {
    this.store.setItem(key, value);
  }
}

function fromStringXY(coord: string): Coordinate {
  return coord.split(',').map(Number);
}

const KEY_CENTER = 'center';
const DEF_CENTER = '0, 0';
const KEY_ZOOM = 'zoom';
const DEF_ZOOM = '2';

class AppStorage {
  session: StorageBackend;
  local: StorageBackend;

  constructor() {
    this.session = new StorageBackend(sessionStorage);
    this.local = new StorageBackend(localStorage);
  }

  setCenter(center: Coordinate | undefined) {
    if (center !== undefined) {
      this.local.set(KEY_CENTER, toStringXY(center));
    }
  }

  getCenter(): Coordinate {
    const centerString = this.local.getOrDefault(KEY_CENTER, DEF_CENTER);
    return fromStringXY(centerString);
  }

  setZoom(zoom: number | undefined) {
    if (zoom !== undefined) {
      this.local.set(KEY_ZOOM, zoom.toString());
    }
  }

  getZoom(): number {
    return Number(this.local.getOrDefault(KEY_ZOOM, DEF_ZOOM));
  }
}

export default new AppStorage();
