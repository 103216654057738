import { KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js';
import keycloak from './keycloak';

interface LoginProps {
  register?: boolean;
}

function realLogin({ register }: LoginProps) {
  const options: KeycloakLoginOptions = {};
  if (register) {
    options.action = 'register';
  }
  options.redirectUri = window.location.href;
  keycloak.login(options);
}

export function login() {
  realLogin({});
}

export function register() {
  realLogin({ register: true });
}

export function logout() {
  const options: KeycloakLogoutOptions = {};
  options.redirectUri = window.location.href;
  keycloak.logout(options);
}

export function updateToken() {
  return keycloak.updateToken(30);
}
