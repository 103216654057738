import { ReactNode, useEffect, useState } from 'react';

import { KeycloakProfile } from 'keycloak-js';

import KeycloakContext from './context';
import { KeycloakUser } from './user';
import keycloak from './keycloak';
import { updateToken } from './actions';

interface KeycloakProviderProps {
  children?: ReactNode;
}

export default function KeycloakProvider({ children }: KeycloakProviderProps) {
  const [refreshInterval, setRefreshInterval] = useState<number>(0);
  const [user, setUser] = useState<KeycloakUser>(new KeycloakUser(keycloak));
  const [profile, setProfile] = useState<KeycloakProfile | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>('');

  keycloak.onAuthSuccess = function () {
    keycloak.loadUserProfile().then(setProfile);

    setRefreshInterval(
      window.setInterval(() => {
        updateToken()
          .then(() => {
            console.log('storing new token');
            setToken(keycloak.token);
          })
          .catch((e) => {
            window.clearInterval(refreshInterval);
          });
      }, 25000),
    );
  };

  keycloak.onTokenExpired = () => {
    console.log('token expired', keycloak.token);
    setUser(new KeycloakUser(keycloak));
  };

  useEffect(() => {
    setUser(new KeycloakUser(keycloak, profile));
  }, [profile]);

  return (
    <KeycloakContext.Provider value={{ user: user, keycloak: keycloak, token: token }}>
      {children}
    </KeycloakContext.Provider>
  );
}
