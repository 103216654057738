import { createContext } from 'react';
import Keycloak from 'keycloak-js';

import User, { KeycloakUser } from './user';
import keycloak from './keycloak';

export interface KeycloakContextProps {
  user: User;
  keycloak: Keycloak;
  token?: string;
}

const KeycloakContext = createContext<KeycloakContextProps>({ user: new KeycloakUser(keycloak), keycloak: keycloak });

export default KeycloakContext;
