import { useState } from 'react';
import { Routes } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { styled, useTheme } from '@mui/material/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';

import AppBar from './AppBar';
import AppList from './AppList';
import DrawerHeader from './DrawerHeader';
import ProfileButton from './ProfileButton';

import * as consts from '../../constants';
import { RoutesProps } from 'react-router-dom';

interface MainProps {
  drawerOpen: boolean;
}

const MainBox = styled('main', {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})<MainProps>(({ theme, drawerOpen }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(drawerOpen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${consts.drawerWidth}px`,
  }),
}));

export default function Main({ children, location }: RoutesProps) {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(true);

  function openDrawer() {
    setDrawerOpen(true);
  }

  function closeDrawer() {
    setDrawerOpen(false);
  }

  return (
    <Box className='App'>
      <AppBar position='fixed' open={drawerOpen}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open app drawer'
            onClick={openDrawer}
            edge='start'
            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon fontSize='large' />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            Super Ruck
          </Typography>
          <ProfileButton />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: consts.drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: consts.drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor='left'
        open={drawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={closeDrawer}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <AppList />
      </Drawer>
      <MainBox drawerOpen={drawerOpen}>
        <DrawerHeader />
        <Routes>{children}</Routes>
      </MainBox>
    </Box>
  );
}
