import React from 'react';
import ReactDOM from 'react-dom/client';
import 'ress';
import './index.css';
import '@fontsource/roboto';

import { HashRouter } from 'react-router-dom';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, blueGrey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: blue[800],
      main: blue[800],
      dark: blue[800],
    },
    secondary: {
      main: blueGrey['200'],
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HashRouter>
        <App />
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
