import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

interface AppIconProps {
  href: string;
  icon: React.ReactNode;
  title: string;
  selected?: boolean;
  sx?: SxProps<Theme>;
  text: string;
}

export default function AppIconButton({ href, selected, icon, title, text }: AppIconProps) {
  const theme = useTheme();

  return (
    <Tooltip title={title}>
      <Link component={NavLink} to={href} sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          sx={{
            mx: 'auto',
            backgroundColor: selected ? theme.palette.primary.main : 'inherit',
            color: selected ? theme.palette.background.default : theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: selected ? theme.palette.primary.dark : 'inherit',
              color: selected ? theme.palette.background.default : 'inherit',
            },
          }}
          size='large'
        >
          {icon}
        </IconButton>
      </Link>
    </Tooltip>
  );
}
