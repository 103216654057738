import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  url: 'https://login.ruck.io',
  realm: 'ruck.io',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
});

keycloak.init({
  checkLoginIframe: false,
});

export default keycloak;
