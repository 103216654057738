import { useEffect, useRef, useState } from 'react';

import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import './map.css';

import Box from '@mui/material/Box';

import OLMap from 'ol/Map';
import OLView from 'ol/View';

import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import OSM from 'ol/source/OSM';
import { Coordinate } from 'ol/coordinate';

import LayerSwitcher from 'ol-layerswitcher';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
// import * as olcs from "olcs/OLCesium.js";

// import Sidebar from '../layout/Sidebar';
// import LayerList from './LayerList';

import storage from '../../storage';

export default function Map() {
  const [map, setMap] = useState<OLMap>();
  const [center, setCenter] = useState<Coordinate>();
  const [zoom, setZoom] = useState<number>();
  const [layers, setLayers] = useState<BaseLayer[]>();

  const mapElement = useRef<HTMLDivElement>();

  useEffect(() => {
    console.log('Storing zoom', zoom);
    storage.setZoom(zoom!);
  }, [zoom]);

  useEffect(() => {
    console.log('Storing center', center);
    storage.setCenter(center!);
  }, [center]);

  useEffect(() => {
    console.log('Updating map layers');
    if (layers && map) {
      map.setLayers(Array.from(layers));
    }
  }, [layers, map]);

  useEffect(() => {
    if (mapElement && mapElement.current && mapElement.current.innerHTML) {
      mapElement.current.innerHTML = '';
    }

    const layerSwitcher = new LayerSwitcher({
      reverse: true,
      groupSelectStyle: 'group',
    });

    const openTopo = new TileLayer({
      title: 'Open Topo',
      type: 'base',
      visible: false,
      source: new XYZ({
        url: 'https://tile.opentopomap.org/{z}/{x}/{y}.png',
      }),
    } as BaseLayerOptions);

    const usgsTopo = new TileLayer({
      title: 'USGS Topo',
      type: 'base',
      visible: true,
      source: new XYZ({
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
      }),
    } as BaseLayerOptions);

    const osm = new TileLayer({
      title: 'OSM',
      type: 'base',
      visible: false,
      source: new OSM(),
    } as BaseLayerOptions);

    const usgsImagery = new TileLayer({
      title: 'USGS Imagery',
      type: 'base',
      visible: true,
      source: new XYZ({
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}',
      }),
    } as BaseLayerOptions);

    const baseMaps = new LayerGroup({
      title: 'Base',
      type: 'base',
      layers: [openTopo, usgsTopo, osm, usgsImagery],
    } as GroupLayerOptions);

    // create map
    const initialMap = new OLMap({
      target: mapElement.current,
      layers: [
        // USGS Topo
        baseMaps,
      ],
      view: new OLView({
        projection: 'EPSG:3857',
        center: storage.getCenter(),
        zoom: storage.getZoom(),
      }),
    });

    initialMap.on('moveend', (evt) => {
      setCenter(evt.map.getView().getCenter());
      setZoom(evt.map.getView().getZoom());
    });

    setMap(initialMap);
    setLayers(initialMap.getAllLayers());
    initialMap.addControl(layerSwitcher);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 64px)' }}>
      <Box
        ref={mapElement}
        sx={{
          display: 'flex',
          height: 'calc(100vh - 64px)',
          flexGrow: 1,
        }}
      ></Box>
    </Box>
  );
}
