import './App.css';

import { Route } from 'react-router-dom';

import Main from './components/layout/Main';

import Dashboard from './components/Dashboard';
import Map from './components/map/Map';
import Data from './components/data/Data';
import { KeycloakProvider } from './hooks/keycloak';

function App() {
  return (
    <KeycloakProvider>
      <Main>
        <Route path='/' element={<Dashboard />} />
        <Route path='/map' element={<Map />} />
        <Route path='/data' element={<Data />} />
      </Main>
    </KeycloakProvider>
  );
}

export default App;
