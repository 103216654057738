import List from '@mui/material/List';
import { useLocation } from 'react-router-dom';

import AppIconButton from './AppIconButton';

import HomeIcon from '@mui/icons-material/HomeRounded';
import MapIcon from '@mui/icons-material/MapRounded';
import DataIcon from '@mui/icons-material/TableRowsRounded';

export default function AppList() {
  const location = useLocation();

  return (
    <List>
      <AppIconButton href='/' title='Home' selected={location.pathname === '/'} icon={<HomeIcon />} text='Home' />
      <AppIconButton
        href='/map'
        title='Map'
        selected={location.pathname.startsWith('/map')}
        icon={<MapIcon />}
        text='Map'
      />
      <AppIconButton
        href='/data'
        title='Data'
        selected={location.pathname.startsWith('/data')}
        icon={<DataIcon />}
        text='Data'
      />
    </List>
  );
}
